import React from 'react'
import {
  Container,
  SimpleGrid,
  Box,
  Text,
  Heading,
  Tag,
} from '@chakra-ui/react'
import SectionHeader from './SectionHeader'
import Section from '../Common/Section'
import { LaptopCode, Gamepad, Beer } from '@emotion-icons/fa-solid'

const experiencedSkills = [
  'C++',
  'C#',
  'PHP',
  'Perl',
  'JavaScript',
  'TypeScript',
  'Laravel',
  'CodeIgniter',
  'React',
  'Next.js',
  'Gatsby',
  'Vue.js',
  'Sass',
  'MySQL',
  'SQL Server',
  'WordPress',
  'AWS',
  'Jenkins',
  'CircleCI',
  'Ansible',
  'Vagrant',
  'Docker',
  'Stripe',
  'Firebase',
]
const learningSkills = ['Go', 'Shopify App' ]

const AboutSection = () => (
  <>
    <Section bg="sky" id="experience" textAlign="center" position="relative">
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        overflow="hidden"
        lineHeight="0"
      >
        <Box w={['200%', '150%', '100%']}>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
            width="100%"
            height="80px"
          >
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              fill="#002440"
            ></path>
          </svg>
        </Box>
      </Box>

      <Container maxW="4xl">
        <SectionHeader>Experience</SectionHeader>
        <Box data-sal="slide-up">
          <LaptopCode size="24" />
        </Box>
        <Text data-sal="slide-up">
          4+ years professional experience of web development
        </Text>
        <Box data-sal="slide-up">
          <Gamepad size="24" />
        </Box>
        <Text data-sal="slide-up">
          5+ years professional experience of video game development
        </Text>
        <Box data-sal="slide-up">
          <Beer size="24" />
        </Box>
        <Text data-sal="slide-up">
          2 years experience of home brewing in the UK
        </Text>

        <SimpleGrid columns={[1, null, 2]} spacing={2} mt="2em">
          <Box>
            <Heading as="h3" size="md" my={4}>
              Professinal Experience
            </Heading>
            {experiencedSkills.map((data, i) => {
              return (
                <Tag key={i} m={1} colorScheme="linkedin">
                  {data}
                </Tag>
              )
            })}
          </Box>
          <Box>
            <Heading as="h3" size="md" my={4}>
              Learning
            </Heading>
            {learningSkills.map((data, i) => {
              return (
                <Tag key={i} m={1} colorScheme="blue">
                  {data}
                </Tag>
              )
            })}
          </Box>
        </SimpleGrid>
      </Container>
    </Section>
  </>
)

export default AboutSection
