import React from 'react'
import { Box, Text, Link } from '@chakra-ui/react'
import SectionHeader from './SectionHeader'
import Section from '../Common/Section'
import { EmailIcon } from '@chakra-ui/icons'
import { Envelope } from '@emotion-icons/fa-solid'
import { Github } from '@emotion-icons/fa-brands'

const ContactSection = () => (
  <>
    <Section id="contact" textAlign="center">
      <SectionHeader>Contact</SectionHeader>
      <Text data-sal="slide-up">
        I’m currently available for freelance work.
      </Text>
      <Text data-sal="slide-up">
        If you want something built using my skills or just chatting about beer,
        feel free to contact me.
      </Text>

      <Box data-sal="slide-up" display="flex" justifyContent="center" mt="16">
        <Box mx={2}>
          <a href="https://github.com/onkbear" aria-label="github">
            <Github size="48" />
          </a>
        </Box>
        <Box mx={2}>
          <a href="mailto:onkbear@gmail.com" aria-label="email">
            <Envelope size="48" />
          </a>
        </Box>
      </Box>
    </Section>
  </>
)

export default ContactSection
