import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import AboutSection from '../components/FrontPage/AboutSection'
import ExperienceSection from '../components/FrontPage/ExperienceSection'
import ContactSection from '../components/FrontPage/ContactSection'
import IceSection from '../components/FrontPage/IceSection'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <AboutSection />
      <IceSection />
      <ExperienceSection />
      <ContactSection />
    </Layout>
  )
}

export default IndexPage
