import React from 'react'
import { Container, Box, Image, Text, Heading } from '@chakra-ui/react'
import Section from '../Common/Section'

const AboutSection = () => (
  <>
    <Section id="about" pb="6rem">
      <Container>
        <Heading
          as="h1"
          textAlign="center"
          size="2xl"
          my="30px"
          bgGradient="linear(to-l, #FF0080, #002440)"
          bgClip="text"
          fontWeight="extrabold"
          data-sal="slide-up"
        >
          Hi, I am Tomoya.
        </Heading>
        <Box display="flex" justifyContent="center">
          <Image
            borderRadius="full"
            boxSize="128px"
            src="/images/bear_night.svg"
            alt="onkbear"
          />
        </Box>
        <Text data-sal="slide-up" textAlign="center" mt={4}>
          I am a Web Developer based in Japan.
        </Text>
      </Container>
    </Section>
  </>
)

export default AboutSection
